import { parseJson } from '../../api'
import { isError, isResponse } from '../../validations'

export const errorHandlerMiddleware =
  ({ errorHandler = () => {} }) =>
  () =>
  (next) =>
  (action) => {
    if (action.type.includes('ERROR') === false) {
      return next(action)
    }

    if (isResponse(action.payload) && action.payload.status >= 500) {
      errorHandler('INTERNAL_SERVER_ERROR', action)
    } else if (isResponse(action.payload)) {
      parseJson(action.payload.clone()).then(({ error }) => {
        errorHandler(error?.code, action)
      })
    } else if (
      isError(action.payload) &&
      /(Network request failed|Failed to fetch)/.test(action.payload.message)
    ) {
      errorHandler('NO_INTERNET_CONNECTION', action)
    }

    return next(action)
  }

import { openInApp } from '@socialquiz/core/actions/resultsActions'
import { I18n } from '@socialquiz/core/i18n'
import { colors } from '@socialquiz/core/styles/colors'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ReactRedux from 'react-redux'
import { Box } from '../components/Box'
import { Text } from '../components/Text'
import { TouchableOpacity } from '../components/TouchableOpacity'
import { hooks } from '../hooks'

/**
 * Snapchat's in app browser misplaces the circle.
 * The window.quokka.isSnapchatInAppBrowser is not working as intended either.
 * We disable the decoration for the initial release.
 */
const HAS_TEXT_DECORATION = false

const Header = () => (
  <Box
    flexDirection="row"
    alignItems="center"
    position="absolute"
    top={0}
    right={0}
    left={0}
    height={51}
    backgroundColor={colors.white[1]}
    borderBottomWidth={1}
    borderBottomStyle="solid"
    borderBottomColor="#F4F4FC"
    paddingLeft={16}>
    <img src={require('../assets/img/Name+logo.png')} height={32} width={128} />
  </Box>
)

const Container = (props) => (
  <Box
    borderTopLeftRadius={32}
    borderTopRightRadius={32}
    borderBottomRightRadius={8}
    borderBottomLeftRadius={8}
    backgroundColor="rgba(178, 178, 254, 1)"
    marginTop={228}
    paddingRight={32}
    paddingBottom={64}
    paddingLeft={32}
    maxWidth={390}
    {...props}
  />
)

export const Screen = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()
  const [decoratedTextRef, decoratedTextRect] = hooks.useClientRect()

  const { username } = match.params

  const openDeeplink = React.useCallback(() => {
    dispatch(openInApp({ username }))
  }, [dispatch, username])

  React.useEffect(() => {
    /**
     * Fails for desktop users and mobile users without the app.
     * Needed to support direct deeplinking in Snacpchat in app browser.
     */
    window.location = `quokka://quokka.qa/${username}`
  }, [])

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <link
          rel="alternate"
          href={`android-app://de.socialquiz.app/quokka.qa/${username}`}
        />
      </Helmet>

      <Box.Center>
        <Header />

        <Container>
          <Box.Center marginTop={-128}>
            <Box.Center
              borderTopLeftRadius={16}
              borderTopRightRadius={16}
              borderBottomLeftRadius={2}
              borderBottomRightRadius={2}
              boxShadow="0px 2px 16px rgba(71, 106, 130, 0.12)"
              backgroundColor={colors.white[1]}
              width="100%">
              <Box.Center
                paddingTop={32}
                paddingHorizontal={20}
                paddingBottom={24}>
                <Text
                  textAlign="center"
                  fontSize={16}
                  fontWeight={600}
                  color={colors.blue[3]}>
                  {`@${username}`}
                </Text>

                <Text
                  textAlign="center"
                  fontSize={14}
                  fontWeight={500}
                  marginTop={6}>
                  {I18n.t('challengedYouForQuiz')}
                </Text>
              </Box.Center>
            </Box.Center>

            <Box.Center
              borderTopLeftRadius={2}
              borderTopRightRadius={2}
              borderBottomLeftRadius={16}
              borderBottomRightRadius={16}
              boxShadow="0px 24px 32px rgba(31, 47, 128, 0.16)"
              backgroundColor={colors.white[1]}
              width="100%"
              marginTop={2}>
              <Box.Center
                paddingTop={24}
                paddingHorizontal={32}
                paddingBottom={24}>
                <Text textAlign="center" fontSize={16} fontWeight={600}>
                  {I18n.t('doYouTakeIt')}
                </Text>

                <TouchableOpacity
                  onPress={openDeeplink}
                  style={{
                    backgroundColor: colors.blue[1],
                    borderRadius: 16,
                    paddingHorizontal: 29,
                    paddingVertical: 16,
                    marginTop: 16,
                  }}>
                  <Text
                    textAlign="center"
                    fontSize={14}
                    fontWeight={600}
                    color={colors.white[1]}>
                    {I18n.t('acceptChallenge')}
                  </Text>
                </TouchableOpacity>
              </Box.Center>
            </Box.Center>
          </Box.Center>

          <Box.Center paddingHorizontal={16}>
            <Text
              fontSize={16}
              fontWeight={600}
              marginTop={64}
              textAlign="center"
              color={colors.white[1]}>
              {I18n.t('funQuizAppWithDifferentTake')}
            </Text>

            <Text
              fontSize={16}
              fontWeight={600}
              marginTop={16}
              textAlign="center"
              color={colors.white[1]}>
              {I18n.t('playAgainstFriends')}
            </Text>
            <Text
              ref={decoratedTextRef}
              fontSize={16}
              fontWeight={600}
              textAlign="center"
              color={colors.white[1]}>
              {I18n.t('playAlsoWithFriends')}
            </Text>

            {HAS_TEXT_DECORATION && decoratedTextRect.width > 0 && (
              <Box
                position="absolute"
                top={decoratedTextRect.top}
                right={decoratedTextRect.right}
                bottom={decoratedTextRect.bottom}
                left={decoratedTextRect.left - 24}>
                <img
                  src={require('../assets/img/drawn-circle.png')}
                  height={decoratedTextRect.height + 16}
                  width={decoratedTextRect.width + 48}
                />
              </Box>
            )}

            <TouchableOpacity
              onPress={openDeeplink}
              style={{
                backgroundColor: colors.white[1],
                borderRadius: 16,
                paddingHorizontal: 64,
                paddingVertical: 16,
                marginTop: 64,
              }}>
              <Text
                textAlign="center"
                fontSize={14}
                fontWeight={600}
                color={colors.blue[3]}>
                {I18n.t('downloadApp')}
              </Text>
            </TouchableOpacity>
          </Box.Center>
        </Container>
      </Box.Center>
    </>
  )
}
